<template>

    <div class="card">
        <div class="card-header">
            <h3><i class="fa fa-taxi"></i> LEASYS </h3>
        </div>
        <div class="card-body">
            <div class="row">
                <div class="col-sm-12">

                    <form v-on:submit.prevent="makeRequest" id="prodUberForm" novalidate>

                        <div class="form-group" :class="{ 'has-error': $v.requestData.matricula.$error }">
                            <label for="plate"
                                   class="control-label">
                                {{ $t("ProductUseAld.label.plate") }} *
                            </label>
                            <input class="form-control" type="text" id="plate"
                                   :class="{'is-invalid' : $v.requestData.matricula.$error}"
                                   v-model="requestData.matricula">
                            <span class="invalid-feedback">
                                {{ $t("root.required") }}
                            </span>
                        </div>

                        <div class="form-group" :class="{ 'has-error': $v.requestData.tipo.$error }">
                            <label for="selectedInterventionType" class="control-label">
                                {{ $t("ProductUseAld.label.intType") }} *
                            </label>
                            <select class="form-control mb-2 mr-sm-2 mb-sm-0"
                                    :class="{'is-invalid' : $v.requestData.tipo.$error}"
                                    v-model="requestData.tipo"
                                    id="selectedInterventionType"
                                    name="selectedInterventionType">
                                <option value="1">Revisão</option>
                                <option value="2">Avaria</option>
                                <option value="3">Acidente</option>
                                <option value="4">Pneus</option>
                                <option value="5">IPO</option>
                                <option value="6">Vidros</option>
                                <option value="7">Atestos de Óleo</option>
                                <option value="8">Acidente-Imobilizado</option>
                            </select>
                            <span class="invalid-feedback">
                                {{ $t("root.required") }}
                            </span>
                        </div>

                        <div class="form-group" :class="{ 'has-error': $v.requestData.tipoNetwork.$error }">
                            <label for="tipoNetwork" class="control-label">
                                Rede
                            </label>
                            <select class="form-control mb-2 mr-sm-2 mb-sm-0"
                                    :class="{'is-invalid' : $v.requestData.tipoNetwork.$error}"
                                    v-model="requestData.tipoNetwork"
                                    id="tipoNetwork"
                                    name="tipoNetwork">
                                <option value="1">1 - Preferencial</option>
                                <option value="2">2 - Normal</option>
                                <option value="3">3 - Recurso</option>
                            </select>
                            <span class="invalid-feedback">
                                {{ $t("root.required") }}
                            </span>
                        </div>

                        <div class="form-group" :class="{ 'has-error': $v.requestData.distritoId.$error }">
                            <label for="selectedDistrict" class="control-label">
                                {{ $t("ProductUseAld.label.district") }} *
                            </label>
                            <select class="form-control mb-2 mr-sm-2 mb-sm-0"
                                    :class="{'is-invalid' : $v.requestData.distritoId.$error}"
                                    v-model="requestData.distritoId"
                                    id="selectedDistrict"
                                    name="selectedDistrict">
                                <option value="1">Aveiro</option>
                                <option value="2">Beja</option>
                                <option value="3">Braga</option>
                                <option value="4">Bragança</option>
                                <option value="5">Castelo Branco</option>
                                <option value="6">Coimbra</option>
                                <option value="7">Évora</option>
                                <option value="8">Faro</option>
                                <option value="9">Guarda</option>
                                <option value="10">Leiria</option>
                                <option value="11">Lisboa</option>
                                <option value="12">Portalegre</option>
                                <option value="13">Porto</option>
                                <option value="14">Santarém</option>
                                <option value="15">Setúbal</option>
                                <option value="16">Viana do Castelo</option>
                                <option value="17">Vila Real</option>
                                <option value="18">Viseu</option>
                                <option value="31">Ilha da Madeira</option>
                                <option value="32">Ilha de Porto Santo</option>
                                <option value="41">Ilha de Santa Maria</option>
                                <option value="42">Ilha de São Miguel</option>
                                <option value="43">Ilha Terceira</option>
                                <option value="44">Ilha da Graciosa</option>
                                <option value="45">Ilha de São Jorge</option>
                                <option value="46">Ilha do Pico</option>
                                <option value="47">Ilha do Faial</option>
                                <option value="48">Ilha das Flores</option>
                                <option value="49">Ilha do Corvo</option>
                            </select>
                            <span class="invalid-feedback">
                                {{ $t("root.required") }}
                            </span>
                        </div>

                        <div class="form-group">
                            <label for="selectedConcelho" class="control-label">
                                {{ $t("ProductUseAld.label.county") }}
                            </label>
                            <select class="form-control mb-2 mr-sm-2 mb-sm-0"
                                    id="selectedConcelho"
                                    v-model="requestData.concelhoId"
                                    name="selectedConcelho">
                                <option v-for="concelho in concelhosList"
                                        :value="concelho.countyID"
                                        :key="concelho.countyID">{{ concelho.name }}
                                </option>
                            </select>
                        </div>

                        <div class="form-group">
                            <label for="morada"
                                   class="control-label">
                                {{ $t("ProductUseAld.label.address") }}
                            </label>
                            <input class="form-control" type="text" id="morada"
                                   v-model="requestData.morada">
                        </div>

                        <div class="row">
                            <div class="col-12 d-flex mb-2 justify-content-end">
                                <div class="">
                                    <button type="button" class="btn btn-link"
                                            :disabled="loading"
                                            @click.stop.prevent="cleanForm">
                                        Limpar
                                    </button>
                                    <v-button type="submit"
                                              class="btn btn-primary zap-btn-rosa"
                                              :loading="loading"
                                              :disabled="$v.$invalid">
                                        Pesquisar
                                    </v-button>
                                </div>
                            </div>
                        </div>

                    </form>
                </div>

            </div>
        </div>

<!--        <div class="card-body border-top" v-if="resultsList && resultsList.length > 0">-->
        <div class="card-body border-top">

            <h5 class="card-title mb-3">Resultados de pesquisa</h5>
            <div class="row" style="font-weight:bold;">
                <div class="col-3">
                    Nome
                </div>
                <div class="col-2">
                    &nbsp;
                </div>
                <div class="col-4">
                    Morada
                </div>
                <div class="col-2">
                    Telefone
                </div>
                <div class="col">
                    Distância
                </div>
            </div>

            <div v-for="(result, idx) in resultsList" :key="idx"
                 class="row border-bottom mt-2">
                <div class="col-3">
                    <p>
                        <a href="#" class="btn btn-link"
                           type="button"
                           @click.stop.prevent="showNextIdx = idx">
                            {{ result.nome }}
                        </a>&nbsp;
                        <button v-show="showNextIdx === idx" type="button"
                                class="btn btn-success"
                                @click="upsertRecordSF(result)">
                            Seguinte
                        </button>
                    </p>
                </div>
                <div class="col-2">
                    {{result.nome}}
                </div>
                <div class="col-4">
                    <p>{{ result.morada }} {{ result.codpostal }}</p>
                </div>
                <div class="col-2">
                    <p>{{ result.telefone }}</p>
                </div>
                <div class="col">
                    <p>{{ parseDistance(result.distancia) }}</p>
                </div>
            </div>

        </div>

    </div>

</template>


<script>
import axios from "axios";
import {required} from "vuelidate/lib/validators";
import {showSuccessDialog} from "@/utils";

import VButton from "@/components/VButton.vue";
import $ from "jquery";
import toastr from "toastr";
import {i18n} from "../main";
import {getQueryParameterByName} from "../utils";


const allConcelhosList = [
    {
        "countyID": "1",
        "districtID": "1",
        "name": "Águeda"
    },
    {
        "countyID": "1",
        "districtID": "2",
        "name": "Aljustrel"
    },
    {
        "countyID": "1",
        "districtID": "3",
        "name": "Amares"
    },
    {
        "countyID": "1",
        "districtID": "4",
        "name": "Alfândega da Fé"
    },
    {
        "countyID": "1",
        "districtID": "5",
        "name": "Belmonte"
    },
    {
        "countyID": "1",
        "districtID": "6",
        "name": "Arganil"
    },
    {
        "countyID": "1",
        "districtID": "7",
        "name": "Alandroal"
    },
    {
        "countyID": "1",
        "districtID": "8",
        "name": "Albufeira"
    },
    {
        "countyID": "1",
        "districtID": "9",
        "name": "Aguiar da Beira"
    },
    {
        "countyID": "1",
        "districtID": "10",
        "name": "Alcobaça"
    },
    {
        "countyID": "1",
        "districtID": "11",
        "name": "Alenquer"
    },
    {
        "countyID": "1",
        "districtID": "12",
        "name": "Alter do Chão"
    },
    {
        "countyID": "1",
        "districtID": "13",
        "name": "Amarante"
    },
    {
        "countyID": "1",
        "districtID": "14",
        "name": "Abrantes"
    },
    {
        "countyID": "1",
        "districtID": "15",
        "name": "Alcácer do Sal"
    },
    {
        "countyID": "1",
        "districtID": "16",
        "name": "Arcos de Valdevez"
    },
    {
        "countyID": "1",
        "districtID": "17",
        "name": "Alijó"
    },
    {
        "countyID": "1",
        "districtID": "18",
        "name": "Armamar"
    },
    {
        "countyID": "1",
        "districtID": "31",
        "name": "Calheta (Madeira)"
    },
    {
        "countyID": "1",
        "districtID": "32",
        "name": "Porto Santo"
    },
    {
        "countyID": "1",
        "districtID": "41",
        "name": "Vila do Porto"
    },
    {
        "countyID": "1",
        "districtID": "42",
        "name": "Lagoa (São Miguel)"
    },
    {
        "countyID": "1",
        "districtID": "43",
        "name": "Angra do Heroísmo"
    },
    {
        "countyID": "1",
        "districtID": "44",
        "name": "Santa Cruz da Graciosa"
    },
    {
        "countyID": "1",
        "districtID": "45",
        "name": "Calheta (São Jorge)"
    },
    {
        "countyID": "1",
        "districtID": "46",
        "name": "Lajes do Pico"
    },
    {
        "countyID": "1",
        "districtID": "47",
        "name": "Horta"
    },
    {
        "countyID": "1",
        "districtID": "48",
        "name": "Lajes das Flores"
    },
    {
        "countyID": "1",
        "districtID": "49",
        "name": "Corvo"
    },
    {
        "countyID": "2",
        "districtID": "1",
        "name": "Albergaria-a-Velha"
    },
    {
        "countyID": "2",
        "districtID": "2",
        "name": "Almodôvar"
    },
    {
        "countyID": "2",
        "districtID": "3",
        "name": "Barcelos"
    },
    {
        "countyID": "2",
        "districtID": "4",
        "name": "Bragança"
    },
    {
        "countyID": "2",
        "districtID": "5",
        "name": "Castelo Branco"
    },
    {
        "countyID": "2",
        "districtID": "6",
        "name": "Cantanhede"
    },
    {
        "countyID": "2",
        "districtID": "7",
        "name": "Arraiolos"
    },
    {
        "countyID": "2",
        "districtID": "8",
        "name": "Alcoutim"
    },
    {
        "countyID": "2",
        "districtID": "9",
        "name": "Almeida"
    },
    {
        "countyID": "2",
        "districtID": "10",
        "name": "Alvaiázere"
    },
    {
        "countyID": "2",
        "districtID": "11",
        "name": "Arruda dos Vinhos"
    },
    {
        "countyID": "2",
        "districtID": "12",
        "name": "Arronches"
    },
    {
        "countyID": "2",
        "districtID": "13",
        "name": "Baião"
    },
    {
        "countyID": "2",
        "districtID": "14",
        "name": "Alcanena"
    },
    {
        "countyID": "2",
        "districtID": "15",
        "name": "Alcochete"
    },
    {
        "countyID": "2",
        "districtID": "16",
        "name": "Caminha"
    },
    {
        "countyID": "2",
        "districtID": "17",
        "name": "Boticas"
    },
    {
        "countyID": "2",
        "districtID": "18",
        "name": "Carregal do Sal"
    },
    {
        "countyID": "2",
        "districtID": "31",
        "name": "Câmara de Lobos"
    },
    {
        "countyID": "2",
        "districtID": "42",
        "name": "Nordeste"
    },
    {
        "countyID": "2",
        "districtID": "43",
        "name": "Praia da Vitória"
    },
    {
        "countyID": "2",
        "districtID": "45",
        "name": "Velas"
    },
    {
        "countyID": "2",
        "districtID": "46",
        "name": "Madalena"
    },
    {
        "countyID": "2",
        "districtID": "48",
        "name": "Santa Cruz das Flores"
    },
    {
        "countyID": "3",
        "districtID": "1",
        "name": "Anadia"
    },
    {
        "countyID": "3",
        "districtID": "2",
        "name": "Alvito"
    },
    {
        "countyID": "3",
        "districtID": "3",
        "name": "Braga"
    },
    {
        "countyID": "3",
        "districtID": "4",
        "name": "Carrazeda de Ansiães"
    },
    {
        "countyID": "3",
        "districtID": "5",
        "name": "Covilhã"
    },
    {
        "countyID": "3",
        "districtID": "6",
        "name": "Coimbra"
    },
    {
        "countyID": "3",
        "districtID": "7",
        "name": "Borba"
    },
    {
        "countyID": "3",
        "districtID": "8",
        "name": "Aljezur"
    },
    {
        "countyID": "3",
        "districtID": "9",
        "name": "Celorico da Beira"
    },
    {
        "countyID": "3",
        "districtID": "10",
        "name": "Ansião"
    },
    {
        "countyID": "3",
        "districtID": "11",
        "name": "Azambuja"
    },
    {
        "countyID": "3",
        "districtID": "12",
        "name": "Avis"
    },
    {
        "countyID": "3",
        "districtID": "13",
        "name": "Felgueiras"
    },
    {
        "countyID": "3",
        "districtID": "14",
        "name": "Almeirim"
    },
    {
        "countyID": "3",
        "districtID": "15",
        "name": "Almada"
    },
    {
        "countyID": "3",
        "districtID": "16",
        "name": "Melgaço"
    },
    {
        "countyID": "3",
        "districtID": "17",
        "name": "Chaves"
    },
    {
        "countyID": "3",
        "districtID": "18",
        "name": "Castro Daire"
    },
    {
        "countyID": "3",
        "districtID": "31",
        "name": "Funchal"
    },
    {
        "countyID": "3",
        "districtID": "42",
        "name": "Ponta Delgada"
    },
    {
        "countyID": "3",
        "districtID": "46",
        "name": "São Roque do Pico"
    },
    {
        "countyID": "4",
        "districtID": "1",
        "name": "Arouca"
    },
    {
        "countyID": "4",
        "districtID": "2",
        "name": "Barrancos"
    },
    {
        "countyID": "4",
        "districtID": "3",
        "name": "Cabeceiras de Basto"
    },
    {
        "countyID": "4",
        "districtID": "4",
        "name": "Freixo de Espada à Cinta"
    },
    {
        "countyID": "4",
        "districtID": "5",
        "name": "Fundão"
    },
    {
        "countyID": "4",
        "districtID": "6",
        "name": "Condeixa-a-Nova"
    },
    {
        "countyID": "4",
        "districtID": "7",
        "name": "Estremoz"
    },
    {
        "countyID": "4",
        "districtID": "8",
        "name": "Castro Marim"
    },
    {
        "countyID": "4",
        "districtID": "9",
        "name": "Figueira de Castelo Rodrigo"
    },
    {
        "countyID": "4",
        "districtID": "10",
        "name": "Batalha"
    },
    {
        "countyID": "4",
        "districtID": "11",
        "name": "Cadaval"
    },
    {
        "countyID": "4",
        "districtID": "12",
        "name": "Campo Maior"
    },
    {
        "countyID": "4",
        "districtID": "13",
        "name": "Gondomar"
    },
    {
        "countyID": "4",
        "districtID": "14",
        "name": "Alpiarça"
    },
    {
        "countyID": "4",
        "districtID": "15",
        "name": "Barreiro"
    },
    {
        "countyID": "4",
        "districtID": "16",
        "name": "Monção"
    },
    {
        "countyID": "4",
        "districtID": "17",
        "name": "Mesão Frio"
    },
    {
        "countyID": "4",
        "districtID": "18",
        "name": "Cinfães"
    },
    {
        "countyID": "4",
        "districtID": "31",
        "name": "Machico"
    },
    {
        "countyID": "4",
        "districtID": "42",
        "name": "Povoação"
    },
    {
        "countyID": "5",
        "districtID": "1",
        "name": "Aveiro"
    },
    {
        "countyID": "5",
        "districtID": "2",
        "name": "Beja"
    },
    {
        "countyID": "5",
        "districtID": "3",
        "name": "Celorico de Basto"
    },
    {
        "countyID": "5",
        "districtID": "4",
        "name": "Macedo de Cavaleiros"
    },
    {
        "countyID": "5",
        "districtID": "5",
        "name": "Idanha-a-Nova"
    },
    {
        "countyID": "5",
        "districtID": "6",
        "name": "Figueira da Foz"
    },
    {
        "countyID": "5",
        "districtID": "7",
        "name": "Évora"
    },
    {
        "countyID": "5",
        "districtID": "8",
        "name": "Faro"
    },
    {
        "countyID": "5",
        "districtID": "9",
        "name": "Fornos de Algodres"
    },
    {
        "countyID": "5",
        "districtID": "10",
        "name": "Bombarral"
    },
    {
        "countyID": "5",
        "districtID": "11",
        "name": "Cascais"
    },
    {
        "countyID": "5",
        "districtID": "12",
        "name": "Castelo de Vide"
    },
    {
        "countyID": "5",
        "districtID": "13",
        "name": "Lousada"
    },
    {
        "countyID": "5",
        "districtID": "14",
        "name": "Benavente"
    },
    {
        "countyID": "5",
        "districtID": "15",
        "name": "Grândola"
    },
    {
        "countyID": "5",
        "districtID": "16",
        "name": "Paredes de Coura"
    },
    {
        "countyID": "5",
        "districtID": "17",
        "name": "Mondim de Basto"
    },
    {
        "countyID": "5",
        "districtID": "18",
        "name": "Lamego"
    },
    {
        "countyID": "5",
        "districtID": "31",
        "name": "Ponta do Sol"
    },
    {
        "countyID": "5",
        "districtID": "42",
        "name": "Ribeira Grande"
    },
    {
        "countyID": "6",
        "districtID": "1",
        "name": "Castelo de Paiva"
    },
    {
        "countyID": "6",
        "districtID": "2",
        "name": "Castro Verde"
    },
    {
        "countyID": "6",
        "districtID": "3",
        "name": "Esposende"
    },
    {
        "countyID": "6",
        "districtID": "4",
        "name": "Miranda do Douro"
    },
    {
        "countyID": "6",
        "districtID": "5",
        "name": "Oleiros"
    },
    {
        "countyID": "6",
        "districtID": "6",
        "name": "Góis"
    },
    {
        "countyID": "6",
        "districtID": "7",
        "name": "Montemor-o-Novo"
    },
    {
        "countyID": "6",
        "districtID": "8",
        "name": "Lagoa (Algarve)"
    },
    {
        "countyID": "6",
        "districtID": "9",
        "name": "Gouveia"
    },
    {
        "countyID": "6",
        "districtID": "10",
        "name": "Caldas da Rainha"
    },
    {
        "countyID": "6",
        "districtID": "11",
        "name": "Lisboa"
    },
    {
        "countyID": "6",
        "districtID": "12",
        "name": "Crato"
    },
    {
        "countyID": "6",
        "districtID": "13",
        "name": "Maia"
    },
    {
        "countyID": "6",
        "districtID": "14",
        "name": "Cartaxo"
    },
    {
        "countyID": "6",
        "districtID": "15",
        "name": "Moita"
    },
    {
        "countyID": "6",
        "districtID": "16",
        "name": "Ponte da Barca"
    },
    {
        "countyID": "6",
        "districtID": "17",
        "name": "Montalegre"
    },
    {
        "countyID": "6",
        "districtID": "18",
        "name": "Mangualde"
    },
    {
        "countyID": "6",
        "districtID": "31",
        "name": "Porto Moniz"
    },
    {
        "countyID": "6",
        "districtID": "42",
        "name": "Vila Franca do Campo"
    },
    {
        "countyID": "7",
        "districtID": "1",
        "name": "Espinho"
    },
    {
        "countyID": "7",
        "districtID": "2",
        "name": "Cuba"
    },
    {
        "countyID": "7",
        "districtID": "3",
        "name": "Fafe"
    },
    {
        "countyID": "7",
        "districtID": "4",
        "name": "Mirandela"
    },
    {
        "countyID": "7",
        "districtID": "5",
        "name": "Penamacor"
    },
    {
        "countyID": "7",
        "districtID": "6",
        "name": "Lousã"
    },
    {
        "countyID": "7",
        "districtID": "7",
        "name": "Mora"
    },
    {
        "countyID": "7",
        "districtID": "8",
        "name": "Lagos"
    },
    {
        "countyID": "7",
        "districtID": "9",
        "name": "Guarda"
    },
    {
        "countyID": "7",
        "districtID": "10",
        "name": "Castanheira de Pêra"
    },
    {
        "countyID": "7",
        "districtID": "11",
        "name": "Loures"
    },
    {
        "countyID": "7",
        "districtID": "12",
        "name": "Elvas"
    },
    {
        "countyID": "7",
        "districtID": "13",
        "name": "Marco de Canaveses"
    },
    {
        "countyID": "7",
        "districtID": "14",
        "name": "Chamusca"
    },
    {
        "countyID": "7",
        "districtID": "15",
        "name": "Montijo"
    },
    {
        "countyID": "7",
        "districtID": "16",
        "name": "Ponte de Lima"
    },
    {
        "countyID": "7",
        "districtID": "17",
        "name": "Murça"
    },
    {
        "countyID": "7",
        "districtID": "18",
        "name": "Moimenta da Beira"
    },
    {
        "countyID": "7",
        "districtID": "31",
        "name": "Ribeira Brava"
    },
    {
        "countyID": "8",
        "districtID": "1",
        "name": "Estarreja"
    },
    {
        "countyID": "8",
        "districtID": "2",
        "name": "Ferreira do Alentejo"
    },
    {
        "countyID": "8",
        "districtID": "3",
        "name": "Guimarães"
    },
    {
        "countyID": "8",
        "districtID": "4",
        "name": "Mogadouro"
    },
    {
        "countyID": "8",
        "districtID": "5",
        "name": "Proença-a-Nova"
    },
    {
        "countyID": "8",
        "districtID": "6",
        "name": "Mira"
    },
    {
        "countyID": "8",
        "districtID": "7",
        "name": "Mourão"
    },
    {
        "countyID": "8",
        "districtID": "8",
        "name": "Loulé"
    },
    {
        "countyID": "8",
        "districtID": "9",
        "name": "Manteigas"
    },
    {
        "countyID": "8",
        "districtID": "10",
        "name": "Figueiró dos Vinhos"
    },
    {
        "countyID": "8",
        "districtID": "11",
        "name": "Lourinhã"
    },
    {
        "countyID": "8",
        "districtID": "12",
        "name": "Fronteira"
    },
    {
        "countyID": "8",
        "districtID": "13",
        "name": "Matosinhos"
    },
    {
        "countyID": "8",
        "districtID": "14",
        "name": "Constância"
    },
    {
        "countyID": "8",
        "districtID": "15",
        "name": "Palmela"
    },
    {
        "countyID": "8",
        "districtID": "16",
        "name": "Valença"
    },
    {
        "countyID": "8",
        "districtID": "17",
        "name": "Peso da Régua"
    },
    {
        "countyID": "8",
        "districtID": "18",
        "name": "Mortágua"
    },
    {
        "countyID": "8",
        "districtID": "31",
        "name": "Santa Cruz"
    },
    {
        "countyID": "9",
        "districtID": "1",
        "name": "Santa Maria da Feira"
    },
    {
        "countyID": "9",
        "districtID": "2",
        "name": "Mértola"
    },
    {
        "countyID": "9",
        "districtID": "3",
        "name": "Póvoa de Lanhoso"
    },
    {
        "countyID": "9",
        "districtID": "4",
        "name": "Torre de Moncorvo"
    },
    {
        "countyID": "9",
        "districtID": "5",
        "name": "Sertã"
    },
    {
        "countyID": "9",
        "districtID": "6",
        "name": "Miranda do Corvo"
    },
    {
        "countyID": "9",
        "districtID": "7",
        "name": "Portel"
    },
    {
        "countyID": "9",
        "districtID": "8",
        "name": "Monchique"
    },
    {
        "countyID": "9",
        "districtID": "9",
        "name": "Meda"
    },
    {
        "countyID": "9",
        "districtID": "10",
        "name": "Leiria"
    },
    {
        "countyID": "9",
        "districtID": "11",
        "name": "Mafra"
    },
    {
        "countyID": "9",
        "districtID": "12",
        "name": "Gavião"
    },
    {
        "countyID": "9",
        "districtID": "13",
        "name": "Paços de Ferreira"
    },
    {
        "countyID": "9",
        "districtID": "14",
        "name": "Coruche"
    },
    {
        "countyID": "9",
        "districtID": "15",
        "name": "Santiago do Cacém"
    },
    {
        "countyID": "9",
        "districtID": "16",
        "name": "Viana do Castelo"
    },
    {
        "countyID": "9",
        "districtID": "17",
        "name": "Ribeira de Pena"
    },
    {
        "countyID": "9",
        "districtID": "18",
        "name": "Nelas"
    },
    {
        "countyID": "9",
        "districtID": "31",
        "name": "Santana"
    },
    {
        "countyID": "10",
        "districtID": "1",
        "name": "Ílhavo"
    },
    {
        "countyID": "10",
        "districtID": "2",
        "name": "Moura"
    },
    {
        "countyID": "10",
        "districtID": "3",
        "name": "Terras de Bouro"
    },
    {
        "countyID": "10",
        "districtID": "4",
        "name": "Vila Flor"
    },
    {
        "countyID": "10",
        "districtID": "5",
        "name": "Vila de Rei"
    },
    {
        "countyID": "10",
        "districtID": "6",
        "name": "Montemor-o-Velho"
    },
    {
        "countyID": "10",
        "districtID": "7",
        "name": "Redondo"
    },
    {
        "countyID": "10",
        "districtID": "8",
        "name": "Olhão"
    },
    {
        "countyID": "10",
        "districtID": "9",
        "name": "Pinhel"
    },
    {
        "countyID": "10",
        "districtID": "10",
        "name": "Marinha Grande"
    },
    {
        "countyID": "10",
        "districtID": "11",
        "name": "Oeiras"
    },
    {
        "countyID": "10",
        "districtID": "12",
        "name": "Marvão"
    },
    {
        "countyID": "10",
        "districtID": "13",
        "name": "Paredes"
    },
    {
        "countyID": "10",
        "districtID": "14",
        "name": "Entroncamento"
    },
    {
        "countyID": "10",
        "districtID": "15",
        "name": "Seixal"
    },
    {
        "countyID": "10",
        "districtID": "16",
        "name": "Vila Nova de Cerveira"
    },
    {
        "countyID": "10",
        "districtID": "17",
        "name": "Sabrosa"
    },
    {
        "countyID": "10",
        "districtID": "18",
        "name": "Oliveira de Frades"
    },
    {
        "countyID": "10",
        "districtID": "31",
        "name": "São Vicente"
    },
    {
        "countyID": "11",
        "districtID": "1",
        "name": "Mealhada"
    },
    {
        "countyID": "11",
        "districtID": "2",
        "name": "Odemira"
    },
    {
        "countyID": "11",
        "districtID": "3",
        "name": "Vieira do Minho"
    },
    {
        "countyID": "11",
        "districtID": "4",
        "name": "Vimioso"
    },
    {
        "countyID": "11",
        "districtID": "5",
        "name": "Vila Velha de Ródão"
    },
    {
        "countyID": "11",
        "districtID": "6",
        "name": "Oliveira do Hospital"
    },
    {
        "countyID": "11",
        "districtID": "7",
        "name": "Reguengos de Monsaraz"
    },
    {
        "countyID": "11",
        "districtID": "8",
        "name": "Portimão"
    },
    {
        "countyID": "11",
        "districtID": "9",
        "name": "Sabugal"
    },
    {
        "countyID": "11",
        "districtID": "10",
        "name": "Nazaré"
    },
    {
        "countyID": "11",
        "districtID": "11",
        "name": "Sintra"
    },
    {
        "countyID": "11",
        "districtID": "12",
        "name": "Monforte"
    },
    {
        "countyID": "11",
        "districtID": "13",
        "name": "Penafiel"
    },
    {
        "countyID": "11",
        "districtID": "14",
        "name": "Ferreira do Zêzere"
    },
    {
        "countyID": "11",
        "districtID": "15",
        "name": "Sesimbra"
    },
    {
        "countyID": "11",
        "districtID": "17",
        "name": "Santa Marta de Penaguião"
    },
    {
        "countyID": "11",
        "districtID": "18",
        "name": "Penalva do Castelo"
    },
    {
        "countyID": "12",
        "districtID": "1",
        "name": "Murtosa"
    },
    {
        "countyID": "12",
        "districtID": "2",
        "name": "Ourique"
    },
    {
        "countyID": "12",
        "districtID": "3",
        "name": "Vila Nova de Famalicão"
    },
    {
        "countyID": "12",
        "districtID": "4",
        "name": "Vinhais"
    },
    {
        "countyID": "12",
        "districtID": "6",
        "name": "Pampilhosa da Serra"
    },
    {
        "countyID": "12",
        "districtID": "7",
        "name": "Vendas Novas"
    },
    {
        "countyID": "12",
        "districtID": "8",
        "name": "São Brás de Alportel"
    },
    {
        "countyID": "12",
        "districtID": "9",
        "name": "Seia"
    },
    {
        "countyID": "12",
        "districtID": "10",
        "name": "Óbidos"
    },
    {
        "countyID": "12",
        "districtID": "11",
        "name": "Sobral de Monte Agraço"
    },
    {
        "countyID": "12",
        "districtID": "12",
        "name": "Nisa"
    },
    {
        "countyID": "12",
        "districtID": "13",
        "name": "Porto"
    },
    {
        "countyID": "12",
        "districtID": "14",
        "name": "Golegã"
    },
    {
        "countyID": "12",
        "districtID": "15",
        "name": "Setúbal"
    },
    {
        "countyID": "12",
        "districtID": "17",
        "name": "Valpaços"
    },
    {
        "countyID": "12",
        "districtID": "18",
        "name": "Penedono"
    },
    {
        "countyID": "13",
        "districtID": "1",
        "name": "Oliveira de Azeméis"
    },
    {
        "countyID": "13",
        "districtID": "2",
        "name": "Serpa"
    },
    {
        "countyID": "13",
        "districtID": "3",
        "name": "Vila Verde"
    },
    {
        "countyID": "13",
        "districtID": "6",
        "name": "Penacova"
    },
    {
        "countyID": "13",
        "districtID": "7",
        "name": "Viana do Alentejo"
    },
    {
        "countyID": "13",
        "districtID": "8",
        "name": "Silves"
    },
    {
        "countyID": "13",
        "districtID": "9",
        "name": "Trancoso"
    },
    {
        "countyID": "13",
        "districtID": "10",
        "name": "Pedrógão Grande"
    },
    {
        "countyID": "13",
        "districtID": "11",
        "name": "Torres Vedras"
    },
    {
        "countyID": "13",
        "districtID": "12",
        "name": "Ponte de Sor"
    },
    {
        "countyID": "13",
        "districtID": "13",
        "name": "Póvoa de Varzim"
    },
    {
        "countyID": "13",
        "districtID": "14",
        "name": "Mação"
    },
    {
        "countyID": "13",
        "districtID": "15",
        "name": "Sines"
    },
    {
        "countyID": "13",
        "districtID": "17",
        "name": "Vila Pouca de Aguiar"
    },
    {
        "countyID": "13",
        "districtID": "18",
        "name": "Resende"
    },
    {
        "countyID": "14",
        "districtID": "1",
        "name": "Oliveira do Bairro"
    },
    {
        "countyID": "14",
        "districtID": "2",
        "name": "Vidigueira"
    },
    {
        "countyID": "14",
        "districtID": "3",
        "name": "Vizela"
    },
    {
        "countyID": "14",
        "districtID": "6",
        "name": "Penela"
    },
    {
        "countyID": "14",
        "districtID": "7",
        "name": "Vila Viçosa"
    },
    {
        "countyID": "14",
        "districtID": "8",
        "name": "Tavira"
    },
    {
        "countyID": "14",
        "districtID": "9",
        "name": "Vila Nova de Foz Côa"
    },
    {
        "countyID": "14",
        "districtID": "10",
        "name": "Peniche"
    },
    {
        "countyID": "14",
        "districtID": "11",
        "name": "Vila Franca de Xira"
    },
    {
        "countyID": "14",
        "districtID": "12",
        "name": "Portalegre"
    },
    {
        "countyID": "14",
        "districtID": "13",
        "name": "Santo Tirso"
    },
    {
        "countyID": "14",
        "districtID": "14",
        "name": "Rio Maior"
    },
    {
        "countyID": "14",
        "districtID": "17",
        "name": "Vila Real"
    },
    {
        "countyID": "14",
        "districtID": "18",
        "name": "Santa Comba Dão"
    },
    {
        "countyID": "15",
        "districtID": "1",
        "name": "Ovar"
    },
    {
        "countyID": "15",
        "districtID": "6",
        "name": "Soure"
    },
    {
        "countyID": "15",
        "districtID": "8",
        "name": "Vila do Bispo"
    },
    {
        "countyID": "15",
        "districtID": "10",
        "name": "Pombal"
    },
    {
        "countyID": "15",
        "districtID": "11",
        "name": "Amadora"
    },
    {
        "countyID": "15",
        "districtID": "12",
        "name": "Sousel"
    },
    {
        "countyID": "15",
        "districtID": "13",
        "name": "Valongo"
    },
    {
        "countyID": "15",
        "districtID": "14",
        "name": "Salvaterra de Magos"
    },
    {
        "countyID": "15",
        "districtID": "18",
        "name": "São João da Pesqueira"
    },
    {
        "countyID": "16",
        "districtID": "1",
        "name": "São João da Madeira"
    },
    {
        "countyID": "16",
        "districtID": "6",
        "name": "Tábua"
    },
    {
        "countyID": "16",
        "districtID": "8",
        "name": "Vila Real de Santo António"
    },
    {
        "countyID": "16",
        "districtID": "10",
        "name": "Porto de Mós"
    },
    {
        "countyID": "16",
        "districtID": "11",
        "name": "Odivelas"
    },
    {
        "countyID": "16",
        "districtID": "13",
        "name": "Vila do Conde"
    },
    {
        "countyID": "16",
        "districtID": "14",
        "name": "Santarém"
    },
    {
        "countyID": "16",
        "districtID": "18",
        "name": "São Pedro do Sul"
    },
    {
        "countyID": "17",
        "districtID": "1",
        "name": "Sever do Vouga"
    },
    {
        "countyID": "17",
        "districtID": "6",
        "name": "Vila Nova de Poiares"
    },
    {
        "countyID": "17",
        "districtID": "13",
        "name": "Vila Nova de Gaia"
    },
    {
        "countyID": "17",
        "districtID": "14",
        "name": "Sardoal"
    },
    {
        "countyID": "17",
        "districtID": "18",
        "name": "Sátão"
    },
    {
        "countyID": "18",
        "districtID": "1",
        "name": "Vagos"
    },
    {
        "countyID": "18",
        "districtID": "13",
        "name": "Trofa"
    },
    {
        "countyID": "18",
        "districtID": "14",
        "name": "Tomar"
    },
    {
        "countyID": "18",
        "districtID": "18",
        "name": "Sernancelhe"
    },
    {
        "countyID": "19",
        "districtID": "1",
        "name": "Vale de Cambra"
    },
    {
        "countyID": "19",
        "districtID": "14",
        "name": "Torres Novas"
    },
    {
        "countyID": "19",
        "districtID": "18",
        "name": "Tabuaço"
    },
    {
        "countyID": "20",
        "districtID": "14",
        "name": "Vila Nova da Barquinha"
    },
    {
        "countyID": "20",
        "districtID": "18",
        "name": "Tarouca"
    },
    {
        "countyID": "21",
        "districtID": "14",
        "name": "Ourém"
    },
    {
        "countyID": "21",
        "districtID": "18",
        "name": "Tondela"
    },
    {
        "countyID": "22",
        "districtID": "18",
        "name": "Vila Nova de Paiva"
    },
    {
        "countyID": "23",
        "districtID": "18",
        "name": "Viseu"
    },
    {
        "countyID": "24",
        "districtID": "18",
        "name": "Vouzela"
    }
];


export default {
    name: "ProductUseAld",
    mixins: [],
    components: {
        VButton,
    },

    mounted() {
        this.requestData.matricula = getQueryParameterByName("matricula");
        this.requestData.morada = getQueryParameterByName("morada");
        this.requestData.tipo = getQueryParameterByName("tipo") || "1";
        this.requestData.tipoNetwork = getQueryParameterByName("tipoNetwork") || "1";
        this.requestData.distritoId = getQueryParameterByName("distritoId");
        this.requestData.concelhoId = getQueryParameterByName("concelhoId");
    },

    data() {
        return {
            requestData: {
                matricula: null,
                morada: null,
                tipo: null,
                tipoNetwork: null,
                distritoId: null,
                concelhoId: null,
            },
            loading: false,

            resultsList: [],
            showNextIdx: null,
        }
    },
    validations() {
        return {
            requestData: {
                matricula: {
                    required,
                },
                tipo: {
                    required,
                },
                tipoNetwork: {
                    required,
                },
                distritoId: {
                    required,
                },
            },
        }
    },

    computed: {

        concelhosList() {
            const distId = this.requestData.distritoId;
            if (!distId) {
                return [];
            }
            return allConcelhosList
                .filter(value => value.districtID == distId)
                .sort(a => a.name);
        },
    },
    methods: {
        isJson(str) {
            try {
                JSON.parse(str);
            } catch (e) {
                return false;
            }
            return true;
        },
        handleAjaxError(error, title) {
            if (error.response !== undefined) {
                if (error.response.data) {
                    if (!this.isJson(error.response.data)) {
                        if (error.response.data.indexOf("<!DOCTYPE html>") > -1) {
                            const errorEl = $(error.response.data).find("#error");
                            let message = ''
                            if (errorEl !== null && errorEl.length > 0)
                                message = $(error.response.data).find("#error").html();
                            else {
                                message = $(error.response.data).filter("title").text();
                            }
                            toastr.error(message, title ? title : i18n.tc('error.general_title'))
                        } else if (error.response.data) {
                            toastr.error(error.response.data, title ? title : i18n.tc('error.general_title'))
                        } else {
                            toastr.error(error.message, title ? title : i18n.tc('error.general_title'))
                        }
                    } else {
                        toastr.error(i18n.tc('error.general_description'), title ? title : i18n.tc('error.general_title'))
                        // if (error.response.data.msg) {
                        //     toastr.error(i18n.tc('error.general_description'), title ? title : i18n.tc('error.general_title'))
                        // }
                        console.log(JSON.stringify(error.response.data))
                    }
                } else {
                    toastr.error(error.message, title ? title : i18n.tc('error.general_title'))
                }
            } else if (error.responseText !== undefined) {
                if (error.responseText.trim().length > 0) {
                    toastr.error(error.responseText, title ? title : i18n.tc('error.general_title'))
                } else {
                    toastr.error(error.statusText, title ? title : i18n.tc('error.general_title'))
                }
            } else if (error.message) {
                toastr.error(error.message, title ? title : i18n.tc('error.general_title'))
            } else {
                toastr.error(error, title ? title : i18n.tc('error.general_title'))
            }
        },
        makeRequest() {
            const self = this;
            this.$v.$touch();
            if (this.$v.$invalid) {
                // console.log("### INVALID");
                return;
            }
            this.loading = true;
            // eslint-disable-next-line
            const url = jsRoutes.controllers.ProductAldController.makeRequest().url;

            this.resultsList = [];
            this.showNextIdx = null;

            const requestObj = {
                ...this.requestData,
                auth: getQueryParameterByName("auth")
            }
            axios.post(url, requestObj)
                .then((response) => {
                    // console.debug('response: ', response)
                    this.resultsList = response.data.data;
                    if (this.resultsList.length === 0) {
                        showSuccessDialog('Sucesso!', 'Pesquisa terminada sem resultados!');
                    }
                })
                .catch((error) => {
                    console.log("handle error")
                    self.handleAjaxError(error);
                    // throw error;
                })
                .finally(() => {
                    this.loading = false;
                });
        },

        cleanForm() {
            this.requestData = {
                matricula: null,
                tipo: "1",
                tipoNetwork: "1",
                distritoId: null,
                concelhoId: null,
            };
            this.resultsList = [];
            this.showNextIdx = null;
            this.$v.$reset();

        },

        parseDistance(dist) {
            let tmpValue = dist.replace(' KM', '');
            return Number(tmpValue).toFixed(1) + ' KM';
        },

        // Método que cria e envia os dados para o salesforce através do postMessage.
        upsertRecordSF(oficina) {
            // Url do iframe
            const origin = '*';
            // Estrutura em json que irá ser enviada por postMessage.
            const selectedSearch = {};
            selectedSearch.name = oficina.nome;
            selectedSearch.street = oficina.morada;

            const firstSpaceIndex = oficina.codpostal.indexOf(' ');
            selectedSearch.city = firstSpaceIndex !== -1 ? oficina.codpostal.substring(firstSpaceIndex + 1) : oficina.codpostal;
            selectedSearch.zipcode = firstSpaceIndex !== -1 ? oficina.codpostal.split(' ')[0] : oficina.codpostal;

            console.log("upsertRecordSF " + JSON.stringify(selectedSearch));
            //
            window.top.postMessage(
                // Serializar a mensagem
                JSON.stringify(selectedSearch),
                origin);
        },

    }
}
</script>

<style scoped>

</style>
